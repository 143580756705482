<template>
  <a-row>
    <a-col :span="labelCol">
      <div class="label-title" :style="titleStyle" v-if="title">{{title}}</div>
    </a-col>
    <a-col :span="valueCol">
      <div class="value" :style="valueStyle">
        {{inputValue}}
        <slot name="suffix"></slot>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: [String, Number]
    },

    title: {
      type: String,
      default: '标题'
    },

    valueStyle: {
      type: String,
      default: ''
    },

    titleStyle: {
      type: String,
      default: ''
    },

    labelCol: {
      type: Number,
      default: 4
    },

    valueCol: {
      type: Number,
      default: 16
    }
  },
  setup (props, { emit }) {
    const inputValue = ref(props.value)

    watch(props, e => {
      inputValue.value = e.value
    })

    return {
      inputValue
    }
  }
})
</script>

<style lang="less" scoped>
  .value{
    line-height: 0.32rem;
    display: flex;
  }
  .label-title{
    font-size: 0.14rem;
    line-height: 0.32rem;
  }
</style>
