<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="600"
    :loading="submiting"
    @close="onClose"
    @ok="onSubmitFun"
  >
    <div class="table-list">
      <a-spin :spinning="loading">
        <ul>
          <li
            v-for="(v, k) in tableList"
            :key="k"
            :class="{ on: curTableId === v.table.id }"
            @click="onChoose(v)"
          >
            <div class="li-title">{{ v.table.name }}</div>
            <div class="li-price"><span>¥</span>{{ v.order.payAmount }}</div>

            <div class="li-info-2">
              <div class="info-seat">
                <icon-font type="icon-shafa" />
                {{ v.table.peopleCount }}
              </div>
              <div class="info-people" v-if="v.table.state > 0">
                <icon-font type="icon-yonghu2" style="font-size: 13px" />
                {{ v.order.peopleCount }}
              </div>
            </div>
          </li>

          <div v-if="tableList.length === 0" class="empty">暂无可转台位</div>
        </ul>
      </a-spin>
    </div>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {} from 'ant-design-vue'
import { TableClass } from '@/apis/table'
import { OrderItemClass } from '@/apis/order'

const table = new TableClass()
const order = new OrderItemClass()
export default defineComponent({
  emits: ['ok'],
  components: {},
  setup (props, { emit }) {
    const loading = ref(false)
    const submiting = ref(false)
    const visible = ref(false)
    const title = ref('')
    const tableOrder = ref()
    const tableList = ref([])
    const curTableId = ref(0)
    const orderItem = ref()

    const onClose = () => {
      curTableId.value = 0
      loading.value = false
      visible.value = false
    }

    const onGetTableList = () => {
      loading.value = true

      table
        .all({
          State: 2
        })
        .then((resp) => {
          tableList.value = resp.filter(
            (x) =>
              x.order &&
              x.order.id !== orderItem.value.orderId &&
              x.order.isSplit === false &&
              x.order.isConnect === false
          )
          loading.value = false
        })
    }

    const onChoose = (e) => {
      tableOrder.value = e
      curTableId.value = e.table.id
    }

    return {
      visible,
      loading,
      title,
      tableOrder,
      tableList,
      submiting,
      curTableId,
      orderItem,

      onClose,
      onGetTableList,
      onChoose
    }
  },

  methods: {
    open (orderItem) {
      this.orderItem = orderItem

      this.title = '转台：' + orderItem.dish.name + '(¥' + orderItem.sellingPrice + ')'

      this.onGetTableList()
      this.visible = true
    },

    onSubmitFun () {
      if (this.curTableId === 0) {
        this.$message.error('请选择台位')
        return
      }

      this.submiting = true
      order.move(this.orderItem.id, this.tableOrder.order.id).then(() => {
        this.$message.error('操作成功')
        this.$emit('ok')
        this.submiting = false
        this.onClose()
      })
    },

    chooseIn (e) {
      return this.chooseList.includes(e.order.id)
    }
  }
})
</script>

<style lang="less" scoped>
.table-list {
  position: relative;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    li {
      cursor: pointer;
      width: 130px;
      margin: 5px;
      background: #f9d6d6;
      border-radius: 3px;
      padding: 10px;
      .li-title {
        font-size: 16px;
        line-height: 30px;
      }
      .li-price {
        font-size: 12px;
        line-height: 24px;
      }
      .li-info-2 {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 16px;
        line-height: 32px;
        .info-people {
          padding-left: 16px;
        }
      }
      &.on {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      }
    }
  }
}

.empty {
  font-size: 14px;
  text-align: center;
  line-height: 50px;
  color: #999;
  width: 100%;
}
</style>
