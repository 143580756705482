<template>
  <a-row>
    <a-col :span="labelCol">
      <div class="label-title">{{title}}</div>
    </a-col>
    <a-col :span="valueCol">
      <a-form-item v-bind="validate">
        <a-select v-model:value="inputValue" style="width: 100%;border: 1px solid #999999;border-radius: 0.08rem" :bordered="false" :loading="loading" :options="options" @change="onChange" :disabled="disabled" :placeholder="'请选择' + title">
          <template #suffixIcon><CaretDownOutlined /></template>
        </a-select>
        <span v-if="validate" class="must">*</span>
      </a-form-item>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { CaretDownOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  props: {
    value: {
      type: [String, Number]
    },

    title: {
      type: String,
      default: '标题'
    },

    labelCol: {
      type: Number,
      default: 4
    },

    valueCol: {
      type: Number,
      default: 16
    },

    validate: {
      type: Object,
      default: () => null
    },

    options: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['ok', 'update:value'],
  setup (props, { emit }) {
    const inputValue = ref(props.value)

    watch(props, e => {
      inputValue.value = e.value
    })

    const onChange = e => {
      emit('ok', e)
      emit('update:value', e)
    }

    return {
      inputValue,
      onChange
    }
  },

  components: { CaretDownOutlined }
})
</script>

<style lang="less" scoped>
  .must{
    position: absolute;
    top: 0;
    right: -10px;
    color: #f00;
    font-size: 18px;
    line-height: 32px;
  }

  .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input){
    border-color: #ff4d4f !important
  }

  .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector{
    border-color: transparent !important;
  }
</style>
