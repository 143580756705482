<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="300"
    @close="handleClose"
    @ok="handleOk"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form v-bind="layout">
        <e-input-number
          :labelCol="4"
          :valueCol="16"
          :title="label"
          v-model:value="count"
          :min="1"
        />
      </a-form>
    </a-spin>
  </e-modal>
</template>
<script>
import { defineComponent, ref } from 'vue'
import form from '@/mixins/form'
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  setup (props, { emit }) {
    const visible = ref(false)
    const loading = ref(false)
    const id = ref(0)
    const type = ref(0)
    const count = ref(1)
    const quantity = ref(0)
    const label = ref()
    const title = ref('')

    const handleClose = () => {
      loading.value = false
      visible.value = false
    }

    const handleOk = () => {
      loading.value = true
      emit('ok', {
        id: id.value,
        type: type.value,
        count: count.value
      })
    }

    return {
      loading,
      visible,
      id,
      type,
      count,
      quantity,
      label,
      title,

      handleClose,
      handleOk
    }
  },

  methods: {
    open (item, type) {
      this.id = item.id
      this.type = type
      this.quantity = item.quantity

      this.label = type === 1 ? '添加' : '减少'
      this.title = this.label + item.dish.name
      this.visible = true
    }
  }
})
</script>

<style lang="less" scoped></style>
